<template>
  <CRow>
    <CCol col="12" sm="5" md="4" lg="4">
      <CCard>
        <CCardHeader>
          <h5>{{$t("SETTING_2FA_PAGE_TITLE_QR_CODE")}}</h5>
        </CCardHeader>
        <CCardBody>
          <div class="text-center" v-if="secret2Fa != ''">
            <qrcode-vue :value="qrCode" :size="size" level="H"></qrcode-vue>
          </div>
          <CRow class="mt-3">
            <CCol sm="12">
              <CInput @keyup.enter="verify" :label="$t('SETTING_2FA_PAGE_LABEL_VERIFY_CODE')" v-model="inputCode" autofocus />
            </CCol>
          </CRow>
          <CRow class="btn-verify">
            <CCol col="6">
              <CButton @click="verify" block color="primary" aria-pressed="true">{{$t("SETTING_2FA_PAGE_BUTTON_VERIFY")}}</CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import endpoints from "@/constants/endpoints";
import { AUTH_TWOFA_UPDATE } from "@/store/actions/auth";
import { mapState } from "vuex";

export default {
  data() {
    return {
      secret2Fa: "",
      size: 150,
      meta: "otpauth://totp/SecretKey?secret=",
      inputCode: ""
    };
  },
  computed: {
    qrCode() {
      return this.meta + this.secret2Fa;
    },
    ...mapState({
      twofaEnableFlag: state => state.auth.twofaEnableFlag
    })
  },
  async mounted() {
    if (!this.twofaEnableFlag) {
      try {
        const { data } = await this.$http.get(endpoints.getSecret2Fa);

        this.secret2Fa = data;
      } catch (e) {
        console.log(e);
      }
    }
  },
  methods: {
    verify() {
      let params;
      if (this.twofaEnableFlag) {
        params = {
          twofa_code: this.inputCode.toString(),
          disable: this.twofaEnableFlag
        };
      } else {
        params = {
          twofa_secret: this.secret2Fa,
          twofa_code: this.inputCode.toString(),
          disable: this.twofaEnableFlag
        };
      }

      this.$store.dispatch(AUTH_TWOFA_UPDATE, params).then(res => {
        this.$router.push("/admin/profile");
      });
    }
  },
  components: {
    QrcodeVue
  }
};
</script>

<style lang="scss" scoped>
.btn-verify {
  justify-content: center;
}
</style>
